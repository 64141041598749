<template>
  <div class="about-us-page contacts-page">
    <section class="au-first">
      <div class="container">
        <div class="au-s-links">
          <div class="text">
            <router-link to="/">{{ $t("Bosh sahifa") }}</router-link>
            <span>{{ $t("Aloqa") }}</span>
          </div>
          <share></share>
        </div>
        <div class="au-texts">
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="about-us">
                <div class="sub" v-if="contact!==''" v-html="contact.description">
                  
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="about-us contact-card" v-if="address !== undefined">
                <div class="title">{{ address.title }}</div>
                <div class="sub">{{ address.value }}</div>
                <div class="app-links">
                  <div class="app-google mar-bot" v-if="email !== undefined">
                    <a :href="'mailto:' + email.value" target="_blank">
                      <div class="link-img">
                        <img src="../../assets/images/icons/mes.svg" />
                      </div>
                      <div class="link-sub">{{ email.value }}</div>
                    </a>
                  </div>
                  <div class="app-apple">
                    <div class="link-img">
                      <a v-if="facebook !== undefined" :href="facebook.value">
                        <img src="../../assets/images/icons/fcb.svg" />
                      </a>
                      <a v-if="instagram !== undefined" :href="instagram.value">
                        <img src="../../assets/images/icons/ins.svg" />
                      </a>
                      <a v-if="telegram !== undefined" :href="telegram.value">
                        <img src="../../assets/images/icons/tlg.svg" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sub">{{ $t("09:00 dan 18:00 gacha") }}</div>
                <a
                  v-if="phone !== undefined"
                  :href="'tel:' + phone.value"
                  class="title number"
                  >{{ phone.value }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from "../elements/ContactUs.vue";
import Share from "../elements/Share.vue";
import { mapState } from "vuex";
export default {
  components: {
    ContactUs,
    Share,
  },
  mounted() {
    this.$store.dispatch('contact')
},
  computed: {
    ...mapState(["settings", 'contact']),
    instagram() {
      return this.settings.find((el) => el.slug == "instagram");
    },
    telegram() {
      return this.settings.find((el) => el.slug == "telegram");
    },
    facebook() {
      return this.settings.find((el) => el.slug == "facebook");
    },
    email() {
      return this.settings.find((el) => el.slug == "email");
    },
    phone() {
      return this.settings.find((el) => el.slug == "phone");
    },
    address() {
      return this.settings.find((el) => el.slug == "adress");
    },
  },
  methods: {},
};
</script>

<style>
</style>